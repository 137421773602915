import createApp from 'vue';
import App from './App.vue';
import VueRouter from "vue-router";
import BootstrapVue from 'bootstrap-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Vuetify from 'vuetify';
import IowaRaw from "@/components/IowaRaw";

// createApp(App).mount('#app')
import '@/assets/css/global.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

createApp.use(BootstrapVue, Vuetify);
createApp.use(VueRouter);
createApp.use(VueAxios, axios);


const router = new VueRouter({
    // mode: 'history',
    // path: "./",
    base: '/p',
    routes: [{path: '/:userId', component: IowaRaw}],
});

createApp.config.productionTip = false
new createApp({
    router,
    render: (h) => h(App),
}).$mount('#app');